import React, { useState, useContext } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { add, spinner } from '../ShopProduct/styles.module.scss';
import Spinner from '../../icons/Spinner';
import {
  CartContext,
  useAddItemToCart,
  useToggleCart,
} from '../../contexts/CartContext';

const ToggleGroup = ({ items }) => {
  const {
    cart: { isAdding, currentLineItem },
  } = useContext(CartContext);

  const addItemToCart = useAddItemToCart();
  const toggleCart = useToggleCart();

  const [selectedVariant, setSelectedVariant] = useState(items[0]);

  const handleClick = variant => {
    setSelectedVariant(variant);
  };

  async function handleAddToCart() {
    await addItemToCart(selectedVariant.storefrontId, 1);
    toggleCart(true);
  }

  return (
    <div className={styles.base}>
      {items.map(option => (
        <button
          onClick={() => handleClick(option)}
          className={cx('btn btn--medium btn--secondary', styles.item, {
            selected: option.storefrontId === selectedVariant.storefrontId,
          })}
        >
          $
          {Number.isInteger(option.price)
            ? option.price
            : option.price.toFixed(2)}
        </button>
      ))}
      <div className={cx('d-flex space-y mt-4', styles.add)}>
        <button type="button" className="btn" onClick={handleAddToCart}>
          {selectedVariant.availableForSale ? 'Add to Cart' : 'Pre-order'}
        </button>
        {isAdding && selectedVariant.storefrontId === currentLineItem && (
          <Spinner className={spinner} />
        )}
      </div>
    </div>
  );
};

export default ToggleGroup;
