import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import DefaultLayout from '../layouts';
import VariantItem from '../components/VariantItem';
import styles from '../components/ShopProduct/styles.module.scss';
import ToggleGroup from '../components/ToggleGroup/ToggleGroup';

const ProductTemplate = ({ pageContext }) => {
  const { product } = pageContext;

  return (
    <DefaultLayout>
      <div className="base">
        <div className="container container--medium">
          <div className="breadcrumbs">
            <Link to="/shop">&lsaquo; Back to all products</Link>
          </div>
          <div className={cx('d-flex d-center d-align-start', styles.details)}>
            <div className={styles.image}>
              <img
                src={product.media[0].image.originalSrc}
                alt={product.title}
              />
            </div>
            <div className={styles.description}>
              <h1 className="h2">{product.title}</h1>
              <div
                dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
              />
              {product.variants.length > 1 ? (
                <ToggleGroup items={product.variants} />
              ) : (
                <VariantItem
                  isAvailable={!product.hasOutOfStockVariants}
                  variant={product.variants[0]}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default ProductTemplate;
