import React, { useContext } from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';
import {
  CartContext,
  useAddItemToCart,
  useToggleCart,
} from '../contexts/CartContext';
import Spinner from '../icons/Spinner';
import styles from './ShopProduct/styles.module.scss';

const VariantItem = ({ variant, isAvailable }) => {
  const {
    cart: { isAdding, currentLineItem },
  } = useContext(CartContext);

  const addItemToCart = useAddItemToCart();
  const toggleCart = useToggleCart();

  async function handleAddToCart() {
    await addItemToCart(variant.storefrontId, 1);
    toggleCart(true);
  }

  return (
    <div key={variant.storefrontId}>
      <p className={styles.price}>
        $
        {Number.isInteger(variant.price)
          ? variant.price
          : variant.price.toFixed(2)}
      </p>
      <div className={cx('d-flex', styles.add)}>
        <button className="btn" onClick={handleAddToCart}>
          {isAvailable ? 'Add to Cart' : 'Pre-order'}
        </button>
        {isAdding && variant.storefrontId === currentLineItem && (
          <Spinner className={styles.spinner} />
        )}
      </div>
    </div>
  );
};

export default VariantItem;
